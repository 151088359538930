import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Catalog } from '~api/catalog';
import { isWeb } from '~global/screens';

const initialState = {
  products: [],
  totalProducts: '...',
  categories: [],
  status: {
    products: 'idle',
    totalProducts: 'idle',
    loadMoreProducts: 'idle',
    categories: 'idle',
  },
};

export const getBestSellerCategories = createAsyncThunk(
  'BEST_SELLER/getBestSellerCategories',
  async (params) => {
    const response = await Catalog.getBestSellerProductsRequest(params);
    return response.data;
  },
);

export const getProducts = createAsyncThunk(
  'BEST_SELLER/getProducts',
  async (params) => {
    const deepCopyParams = JSON.parse(JSON.stringify(params));
    deepCopyParams.limit = 20;
    if (deepCopyParams.filter) {
      deepCopyParams.filter.use_limit = true;
    }
    const response = await Catalog.getProductsRequest(deepCopyParams);
    return response.data;
  },
);

export const getTotalProducts = createAsyncThunk(
  'BEST_SELLER/getTotalProducts',
  async (params) => {
    const response = await Catalog.getTotalProductsRequest(params);
    return response.data;
  },
);

export const loadMoreProducts = createAsyncThunk(
  'BEST_SELLER/loadMoreProducts',
  async (params) => {
    const response = await Catalog.getBestSellerLoadProductsRequest(params);
    return response.data;
  },
);

export const getProductsBestSeller = createAsyncThunk(
  'BEST_SELLER/getProductsBestSeller',
  async (params) => {
    const response = await Catalog.getBestSellerLoadProductsRequest(params);
    return response.data;
  },
);

const bestSellerSlice = createSlice({
  name: 'BEST_SELLER',
  initialState,
  reducers: {
    SET_STATE(state, { value, name }) {
      state[name] = value;
    },
    SET_STATUS_API(state, { value, name }) {
      state.status[name] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBestSellerCategories.pending, (state, action) => {
        state.status.categories = 'loading';
      })
      .addCase(getBestSellerCategories.rejected, (state, action) => {
        state.status.categories = 'error';
      })
      .addCase(getBestSellerCategories.fulfilled, (state, { payload }) => {
        state.categories = payload.data;
        state.status.categories = 'finished';
      })
      .addCase(getProducts.pending, (state, action) => {
        state.status.products = 'loading';
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status.products = 'error';
      })
      .addCase(getProducts.fulfilled, (state, { payload }) => {
        state.products = payload.data;
        state.status.products = 'finished';
      })
      .addCase(getTotalProducts.pending, (state, action) => {
        state.status.totalProducts = 'loading';
      })
      .addCase(getTotalProducts.rejected, (state, action) => {
        state.status.totalProducts = 'error';
      })
      .addCase(getTotalProducts.fulfilled, (state, { payload }) => {
        state.totalProducts = payload.data;
        state.status.totalProducts = 'finished';
      })
      .addCase(loadMoreProducts.pending, (state, action) => {
        state.status.loadMoreProducts = 'loading';
      })
      .addCase(loadMoreProducts.rejected, (state, action) => {
        state.status.loadMoreProducts = 'error';
      })
      .addCase(loadMoreProducts.fulfilled, (state, { payload }) => {
        if (isWeb) {
          state.products = payload.data;
        } else {
          const existingIds = state.products.map((product) => product.id);
          const newUniqueProducts = payload.data.filter(
            (product) => !existingIds.includes(product.id),
          );
          state.products = [...state.products, ...newUniqueProducts];
        }

        state.status.loadMoreProducts = 'finished';
      })
      .addCase(getProductsBestSeller.pending, (state, action) => {
        state.status.products = 'loading';
      })
      .addCase(getProductsBestSeller.rejected, (state, action) => {
        state.status.products = 'error';
      })
      .addCase(getProductsBestSeller.fulfilled, (state, { payload }) => {
        state.products = payload.data;
        state.status.products = 'finished';
      });
  },
});

export const { SET_STATE, SET_STATUS_API } = bestSellerSlice.actions;
export default bestSellerSlice.reducer;
